export const sdOrganisation = {
  "@context": "https://schema.org/",
  "@type": "Organization",
  name: "Goldi",
  url: "https://tastegoldi.com",
  logo: "https://tastegoldi.com/logo.svg",
  address: {
    "@type": "PostalAddress",
    streetAddress: "976 Mitchell Rd",
    addressLocality: "Kialla",
    addressRegion: "Victoria",
    postalCode: "3631",
    addressCountry: "Australia",
  },
  email: "hi@tastegoldi.com",
  sameAs: [
    "https://www.facebook.com/tastegoldi",
    "https://www.instagram.com/tastegoldi",
  ],
};

export const sdWebsite = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  name: "Goldi",
  url: "https://tastegoldi.com",
};

export default function setStructuredData(data) {
  // If data is an array, create separate script tags for each item
  const sd = Array.isArray(data)
    ? data.map((item) => ({
        type: "application/ld+json",
        innerHTML: JSON.stringify(item),
        key: `json-ld-${item["@type"]}`, // Add a unique key for each script
      }))
    : [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(data),
          key: `json-ld-${data["@type"]}`,
        },
      ];

  useHead({
    script: sd,
  });
}
